import { useState, useEffect } from "react";
const Gettext = require("gettext.js").default;
const deTranslations = require("../../../config/locales/de/default.json");
const enTranslations = require("../../../config/locales/en/default.json");
const frTranslations = require("../../../config/locales/fr/default.json");
const bgTranslations = require("../../../config/locales/bg/default.json");
const esTranslations = require("../../../config/locales/es/default.json");

// Initialize gettext.js
const i18n = new Gettext();
// Load translations
i18n.loadJSON(deTranslations);
i18n.loadJSON(enTranslations);
i18n.loadJSON(frTranslations);
i18n.loadJSON(bgTranslations);
i18n.loadJSON(esTranslations);

//TO DO: Add more languages!!!

// Create a wrapper function
const translate = (msgid) => i18n.gettext(msgid);

export const useTranslation = () => {
  const [locale, setLocale] = useState(document.documentElement.lang || "bg");

  //function to detect the language from the lang attribute and set the new language to gettext.js
  const updateLocale = async () => {
    const newLang = document.documentElement.lang;
    i18n.setLocale(newLang);
    setLocale(i18n.getLocale());
  };

  useEffect(() => {
    // Observe changes in the `lang` attribute
    const observer = new MutationObserver(updateLocale);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["lang"],
    });

    // Initial run
    updateLocale();

    return () => observer.disconnect();
  }, []);

  return {
    translate,
    locale,
  };
};
