import React, { useState } from "react";

const PagesImageUploader = ({ existingImages = [] }) => {
  const [desiredName, setDesiredName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [imageList, setImageList] = useState(existingImages);

  const handleNameChange = (e) => {
    setDesiredName(e.target.value);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    // The form element that triggered onSubmit:
    const form = e.currentTarget;

    if (!desiredName || !selectedFile) {
      alert("Please provide a desired name and select a file.");
      return;
    }

    // Check for duplicate filename in imageList
    const duplicate = imageList.find((img) => img.filename === desiredName);
    if (duplicate) {
      alert(`A file with the name "${desiredName}" already exists! Please choose a different name.`);
      return;
    }

    try {
      // 1. Request a presigned URL from your Rails backend
      const response = await fetch(
        `/admin/content/pages/presign?filename=${encodeURIComponent(desiredName)}&content_type=${encodeURIComponent(selectedFile.type)}`
      );
      if (!response.ok) {
        throw new Error("Failed to get presigned URL");
      }
      const data = await response.json();

      const { presigned_url, public_url } = data;

      // 2. Upload the file directly to S3
      const uploadResponse = await fetch(presigned_url, {
        method: "PUT",
        headers: {
          "Content-Type": selectedFile.type,
        },
        body: selectedFile,
      });

      if (!uploadResponse.ok) {
        throw new Error("Failed to upload file to S3");
      }

      // 3. Update the UI
      setUploadedImageUrl(public_url);

      // Push the new image to the front of the list
      setImageList((prev) => [
        { url: public_url, filename: desiredName },
        ...prev
      ]);

      // Reset the form fields properly using e.currentTarget
      form.reset();
      setSelectedFile(null);
      setDesiredName("");
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image. Check console for details.");
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      <h2>Image Upload</h2>

      <form onSubmit={handleUpload}>
        <div>
          <label>Filename</label>
          <input
            type="text"
            value={desiredName}
            onChange={handleNameChange}
            placeholder="e.g. myImage.jpg"
            required
            style={{ marginLeft: "10px" }}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <label>Select File:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            required
            style={{ marginLeft: "10px" }}
          />
        </div>
        <button type="submit" className="button small">
          Upload
        </button>
      </form>

      {uploadedImageUrl && (
        <div>
          <h3>Preview:</h3>
          <img
            src={uploadedImageUrl}
            alt="Uploaded to S3"
            style={{ maxWidth: "300px", display: "block" }}
          />
          <label style={{ display: "block", marginTop: "10px" }}>
            S3 Image URL:
            <input
              type="text"
              value={`![alt](${uploadedImageUrl})`}
              
            />
          </label>
        </div>
      )}

      <div>
        <h3>Existing photos</h3>
        {imageList && imageList.length > 0 ? (
          <div className="grid-x">
            {imageList.map((img, index) => (
              <div className="cell medium-4" key={index}>
                <div style={{padding: 4}}>
                  <img
                    src={img.url}
                    alt={`s3-img-${index}`}
                    style={{marginBottom: "5px"}}
                  />
                  <input type="text" value={`![alt](${img.url})`} className="no-margin"  />
                  <small style={{ marginTop: "5px" }}>
                    {img.filename}
                  </small>
                  <hr style={{margin: "5px 0 15px 0"}}/>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No images available.</p>
        )}
      </div>
    </div>
  );
};

export default PagesImageUploader;
